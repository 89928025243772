<template>
  <el-dialog
    top='5vh'
    width="38rem"
    :visible="true"
    :show-close="false"
    custom-class="them-dialog"
    v-loading="$store.state.requestLoading"
  >
    <div slot="title">
      {{ formData.tenantResourceId ? "编辑菜单" : "添加菜单" }}
    </div>
    <el-form :model="formData" ref="formData" label-width="8rem" :rules="rules" :class="[formData.url && 'margin-bottom']">
      <el-form-item label="菜单名称:" prop="name">
        <el-input
          clearable
          size="medium"
          style="width: 60%;"
          v-model="formData.name"
          placeholder="请输入菜单名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="菜单图标:" prop="icon">
        <div
          class="center"
          style="position: relative;color: #A6A6A6;cursor: pointer;border: .5px dashed #ccc;border-radius: .38rem;width: 80px;height: 80px;"
        >
          <input
            type="file"
            @change="handleFileChange"
            style="opacity: 0;position: absolute;left:0;top:0;width: 100%;height: 100%;cursor: pointer;"
          />
          <i
            v-if="!formData.icon"
            class="el-icon-plus"
            style="font-size: 50px;"
          ></i>
          <img
            v-if="formData.icon"
            :src="formData.icon"
            alt=""
            style="width: 66px;"
          />
        </div>
      </el-form-item>
      <el-form-item v-if="formData.url && formData.openMode" label="路由地址:">
        {{ formData.url }}
      </el-form-item>
      <el-form-item v-if="formData.pidName" label="上级菜单:">
        {{ formData.pidName }}
      </el-form-item>
      <el-form-item v-if="formData.url && formData.openMode" label="打开方式:">
        <el-radio-group v-model="formData.openMode">
          <el-radio :label="1">内部打开</el-radio>
          <el-radio :label="2">外部打开</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注:" prop="memo">
        <el-input type="textarea" rows="6" v-model="formData.memo"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import axios from 'axios'
let that = null
export default {
  props: {
    editData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      formData: {
        name: '',
        icon: '',
        memo: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入部门名称', trigger: 'blur' },
          {
            validator: this.$util.validateFeildLength(10),
            trigger: 'blur'
          }
        ],
        icon: [{ required: true, message: '菜单图标不能为空', trigger: 'blur' }],
        memo: [{ validator: this.$util.validateFeildLength(100), trigger: 'blur' }]
      }
    }
  },
  created () {
    that = this
    that.editData && (that.formData = { ...that.editData })
  },
  methods: {
    checkFile (target, callback = null) {
      const file = target.files[0]
      if (!file) {
        return
      }

      // 验证类型
      if (file.type !== 'image/png') {
        return that.$confirm('上传图标类型仅支持png格式', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        })
          .then(async () => {})
          .catch(() => {})
      }

      // 验证大小，1M以内
      if (file.size / 1024 > 1024) {
        target.value = ''
        // 大于1024k，也就是1M，则返回false
        return that.$confirm('图标大小不能超过1M', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        })
          .then(async () => {})
          .catch(() => {})
      }

      // 验证像素大小
      var reader = new FileReader()
      reader.onload = function (e) {
        // 加载图片获取图片真实宽度和高度
        var image = new Image()
        image.onload = function () {
          var width = image.width
          var height = image.height
          if (width > 100 || height > 100) {
            return that.$confirm('上传图标像素不能大于100*100', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'error'
            })
              .then(async () => {})
              .catch(() => {})
          } else if (width !== height) {
            return that.$confirm('上传图标需要是正方形图标', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'error'
            })
              .then(async () => {})
              .catch(() => {})
          } else {
            callback && callback()
          }
        }
        image.src = e.target.result
      }

      reader.readAsDataURL(file)
    },
    async handleFileChange (e) {
      that.checkFile(e.target, () => {
        const formData = new FormData() // 创建form对象
        formData.append('data', e.target.files[0])
        that.$store.commit('SET_LOADING', true)
        axios
          .post('/apiEx/tenant/tenant-oms/v1/resource/icon-upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data;'
            }
          })
          .then(res => {
            e.target.value = ''
            that.$store.commit('SET_LOADING', false)
            if (res.status === 200 && res.data.code === 0) {
              that.formData.icon = res.data.data
              that.$message({
                message: '上传成功',
                type: 'success'
              })
            } else {
              that.$message({
                message: '上传失败',
                type: 'error'
              })
            }
          })
          .catch(() => {
            e.target.value = ''
            that.$store.commit('SET_LOADING', false)
          })
      })
    },
    submit () {
      //  1.校验数据
      that.$refs.formData.validate(async valid => {
        if (valid) {
          // 验证通过
          // 2.组装数据
          // 3.发送请求
          let res = null
          if (that.formData.tenantResourceId) {
            // 修改
            res = await that.$api.updateResource(that.formData)
            if (res !== 'fail&&error') {
              that.$message({
                message: '修改成功',
                type: 'success'
              })
            } else {
              that.$message({
                message: '修改失败',
                type: 'error'
              })
            }
          } else {
            // 添加
            res = await that.$api.saveResource(that.formData)
            if (res !== 'fail&&error') {
              that.$message({
                message: '添加成功',
                type: 'success'
              })
            } else {
              that.$message({
                message: '添加失败',
                type: 'error'
              })
            }
          }
          that.$emit('close', 'reload')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader {
  .avatar-uploader-icon {
    width: 66px;
    height: 66px;
    cursor: pointer;
    line-height: 66px;
    text-align: center;
    border-radius: 6px;
    border: 1px dashed #d9d9d9;
  }
  .avatar {
    width: 66px;
    height: 66px;
    display: block;
  }
}
.margin-bottom{
  .el-form-item{
    margin-bottom: .66rem;
  }
}
</style>
