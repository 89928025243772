<template>
  <div class="dragTable">
    <div class="tr th">
      <div style="flex: 1; min-width: 10rem">菜单名称</div>
      <div style="flex: 1; min-width: 10rem">
        <div
          style="
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          路由地址
        </div>
      </div>
      <div style="flex: 1; min-width: 10rem">创建时间</div>
      <div style="flex: 1; min-width: 10rem">备注</div>
      <div style="width: 160px">操作</div>
    </div>
    <draggable
      v-model="tableData"
      :group="{ name: 'scene' }"
      animation="300"
      chosenClass="chosen"
      handle=".mover"
      :move="onMove"
      @sort="onSort"
      @unchoose="unchoose"
    >
      <div v-for="(item1, index1) in tableData" :key="index1">
        <div class="tr">
          <div style="flex: 1; min-width: 10rem">
            <span style="display: inline-block; width: 1.23rem">
              <i
                v-if="item1.url === null"
                @click="foldExpandItem(index1)"
                :class="[
                  'pointer',
                  item1.showChildren
                    ? 'el-icon-arrow-down'
                    : 'el-icon-arrow-right',
                ]"
              ></i>
            </span>
            <span
              v-if="!item1.url && item1.children && item1.children.length === 0"
              style="cursor: move; color: red"
              class="mover"
              >{{ item1.name }}</span
            >
            <span v-else style="cursor: move" class="mover">{{
              item1.name
            }}</span>
          </div>
          <div style="flex: 1; min-width: 10rem">
            <div
              style="
                width: 10rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ item1.url || '—' }}
            </div>
          </div>
          <div style="flex: 1; min-width: 10rem">
            {{ $util.dateFormat(item1.createTime) }}
          </div>
          <div style="flex: 1; min-width: 10rem">{{ item1.memo || '—' }}</div>
          <div style="width: 160px">
            <el-button size="mini" @click="handleEdit(item1)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="!item1.url && item1.children && item1.children.length === 0"
              @click="handleDelete(item1.tenantResourceId)"
              >删除</el-button
            >
          </div>
        </div>

        <draggable
          v-if="item1.showChildren"
          v-model="item1.children"
          :group="{ name: 'scene' }"
          animation="300"
          chosenClass="chosen"
          handle=".mover"
          :move="onMove"
          @sort="onSort"
          @unchoose="unchoose"
        >
          <div v-for="(item2, index2) in item1.children" :key="index2">
            <div class="tr">
              <div style="flex: 1; min-width: 10rem">
                <span style="padding-left: 2.8rem"></span>

                <span
                  style="
                    display: inline-block;
                    cursor: move;
                    width: auto;
                    max-width: calc(100% - 2.8rem);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  class="mover"
                  >{{ item2.name }}</span
                >
              </div>
              <div style="flex: 1; min-width: 10rem">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item2.url"
                  placement="top"
                >
                  <div
                    style="
                      width: 90%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item2.url }}
                  </div>
                </el-tooltip>
              </div>
              <div style="flex: 1; min-width: 10rem">
                {{ $util.dateFormat(item2.createTime) }}
              </div>
              <div style="flex: 1; min-width: 10rem">{{ item2.memo }}</div>
              <div style="width: 160px">
                <el-button size="mini" @click="handleEdit(item2)"
                  >编辑</el-button
                >
              </div>
            </div>
          </div>
          <div v-if="!item1.url && item1.children.length === 0">
            <div class="tr"></div>
          </div>
        </draggable>
      </div>
    </draggable>
  </div>
</template>

<script>
import * as _ from 'lodash'
import draggable from 'vuedraggable'
let that = null
export default {
  components: {
    draggable,
  },
  props: {
    originTableData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    originTableData(newVal) {
      that.assemblyTableData(newVal)
    },
  },
  data() {
    return {
      tableData: [],
      isChoose: false,
      postData: {
        sort: 1,
        resourcePid: '0',
        tenantResourceId: null,
      },
    }
  },
  mounted() {
    that = this
    that.assemblyTableData(that.originTableData)
  },
  methods: {
    assemblyTableData(originData) {
      that.tableData = _.cloneDeep(originData)
      that.tableData &&
        that.tableData.length &&
        that.tableData.map((item1) => {
          item1.showChildren = false
        })
    },

    // 展开折叠某项
    foldExpandItem(index) {
      that.tableData[index].showChildren = !that.tableData[index].showChildren
      that.tableData = [...that.tableData]
    },
    // 展开折叠全部
    foldExpandAll(bool) {
      that.tableData = that.tableData.map((item) => {
        item.showChildren = bool
        return item
      })
    },
    // 删除部门
    handleDelete(tenantResourceId) {
      that
        .$confirm('您确认要删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error',
        })
        .then(async () => {
          const res = await that.$api.deleteResource({
            tenantResourceId,
          })
          if (res !== 'fail&&error') {
            that
              .$confirm('删除成功', '提示', {
                confirmButtonText: '确定',
                type: 'success',
              })
              .then(() => {
                location.reload()
              })
              .catch(() => {
                location.reload()
              })
          } else {
            that
              .$confirm('删除失败', '提示', {
                confirmButtonText: '确定',
                type: 'error',
              })
              .then(() => {})
              .catch(() => {})
          }
        })
        .catch(() => {})
    },
    // 编辑部门
    handleEdit(item) {
      that.$emit('edit', {
        item,
        tableData: that.tableData,
      })
    },
    onMove(e) {
      if (!that.isChoose) {
        that.isChoose = true
        that.postData.tenantResourceId =
          e.draggedContext.element.tenantResourceId
      }

      // 如果拖拽的是一级菜单，则不允许拖拽到功能页
      if (
        !e.draggedContext.element.url &&
        (!e.relatedContext.element ||
          (e.relatedContext.element && e.relatedContext.element.url))
      ) {
        return false
      }
      return true
    },
    unchoose(e) {
      that.isChoose = false
    },
    async onSort(e) {
      that.tableData = [...that.tableData]

      that.tableData.map((item1, index1) => {
        // 一级菜单
        if (item1.tenantResourceId === that.postData.tenantResourceId) {
          if (that.tableData.length === 1) {
            // 只有一项的话，只修改父元素
            that.postData.resourcePid = '0'
          } else if (index1 === that.tableData.length - 1) {
            // 如果是最后一个
            that.postData.sort = that.tableData[index1 - 2].sort + 10000
            that.postData.resourcePid = '0'
          } else if (index1 === 0) {
            // 如果是第一个
            that.postData.sort = Math.floor(that.tableData[index1 + 1].sort / 2)
            that.postData.resourcePid = '0'
          } else {
            // 如果不是第一个
            that.postData.sort = Math.floor(
              (that.tableData[index1 + 1].sort +
                that.tableData[index1 - 1].sort) /
                2
            )
            that.postData.resourcePid = '0'
          }
        }

        // 二级菜单
        if (item1.children && item1.children.length > 0) {
          item1.children.map((item2, index2) => {
            if (item2.tenantResourceId === that.postData.tenantResourceId) {
              if (item1.children.length === 1) {
                // 如果只有一个，之更改父元素就行
                that.postData.resourcePid = item1.tenantResourceId
              } else if (index2 === 0) {
                // 如果是第一个
                that.postData.sort = Math.floor(
                  item1.children[index2 + 1].sort / 2
                )
                that.postData.resourcePid = item1.tenantResourceId
              } else if (index2 === item1.children.length - 1) {
                // 如果是最后一个
                that.postData.sort = item1.children[index2 - 1].sort + 10000
                that.postData.resourcePid = item1.tenantResourceId
              } else {
                that.postData.sort = Math.floor(
                  (item1.children[index2 + 1].sort +
                    item1.children[index2 - 1].sort) /
                    2
                )
                that.postData.resourcePid = item1.tenantResourceId
              }
            }
          })
        }
      })

      const res = await that.$api.sortResource(that.postData)
      if (res === 'fail&&error') {
        that
          .$confirm('排序失败', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error',
          })
          .then(async () => {
            location.reload()
          })
          .catch(() => {
            location.reload()
          })
      } else {
        location.reload()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dragTable {
  border: 0.5px solid #e3e3e3;
  border-bottom: none;
  background-color: #f9f9f9;
  .tr {
    border-bottom: 0.5px solid #e3e3e3;
    &.th {
      font-weight: 600;
      color: #555555;
    }
    color: #606266;
    display: flex;
    height: 40px;
    line-height: 40px;
    font-size: 0.9rem;
    & > div {
      padding-left: 1rem;
    }
  }
}

.chosen {
  background-color: #a3cdd9 !important;
  div {
    color: #fff !important;
  }
}
</style>
