<template>
  <div
    class="container-page them-table"
    v-loading="$store.state.requestLoading"
  >
    <el-form
      inline
      :model="searchForm"
      class="flex ai-center"
      @submit.native.prevent
      style="
        border-bottom: none;
        background: #f9f9f9;
        padding: 0.3rem 0.6rem;
        border: 0.5px solid #ebeef5;
      "
    >
      <el-form-item label="菜单名称" style="margin-bottom: 0">
        <el-input
          clearable
          size="small"
          placeholder="全部"
          v-model="searchForm.name"
          @keyup.enter.native="getResourceList"
        ></el-input>
      </el-form-item>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-search"
        @click="getResourceList"
        >查询</el-button
      >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="
          handleEdit({
            item: { name: '', icon: '', memo: '', resourcePid: '0' },
          })
        "
        >添加</el-button
      >
      <el-button size="small" type="primary" @click="foldExpandAll(true)"
        >展开全部</el-button
      >
      <el-button size="small" type="primary" @click="foldExpandAll(false)"
        >折叠全部</el-button
      >
    </el-form>
    <dragTable
      v-if="tableData && tableData.length > 0"
      ref="dragTable"
      :originTableData="tableData"
      @edit="handleEdit"
    ></dragTable>
    <addDialog
      v-if="showDialog"
      @close="hideDialog"
      :editData="editData"
    ></addDialog>
  </div>
</template>

<script>
import addDialog from './addDialog.vue'
import dragTable from './dragTable.vue'

var that = null

export default {
  components: {
    addDialog,
    dragTable
  },
  data () {
    return {
      // 搜索表单
      searchForm: {
        name: ''
      },
      // 表格数据
      tableData: [],
      // 是否显示弹窗
      showDialog: false,
      editData: null
    }
  },
  created () {
    that = this
    that.getResourceList()
  },
  methods: {
    // 获取菜单列表
    async getResourceList () {
      const res = await that.$api.getResourceList(that.searchForm)
      if (res !== 'fail&&error') {
        const webResources = res.find(
          (item) => item.folderCode === 'WEB_MANAGEMENT'
        )
        that.tableData = webResources.list || []
      }
    },
    // 展开或折叠全部
    foldExpandAll (bool) {
      that.$refs.dragTable.foldExpandAll(bool)
    },

    // row：编辑当前行的数据
    handleEdit ({ item, tableData }) {
      this.editData = {
        name: item.name,
        icon: item.icon,
        memo: item.memo,
        tenantResourceId: item.tenantResourceId
      }
      if (item.url) {
        this.editData.url = item.url
        this.editData.openMode = item.openMode
      }
      if (item.resourcePid !== '0') {
        tableData.map((abc) => {
          if (abc.tenantResourceId === item.resourcePid) {
            this.editData.pidName = abc.name
          }
        })
      }
      that.showDialog = true
    },
    hideDialog (param) {
      that.showDialog = false
      if (param === 'reload') {
        that.getResourceList()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
